import "./ResumeDownloadBtn.css";

function ResumeDownloadBtn(props) {
    return (
        <>
            <a href={props.hyperlink || "NaN"} className="resume-btn-container" download="resume.pdf" target="_blank" rel="noopener noreferrer" >
                <p className="resume-btn-caption">Download Résumé</p>
                <svg className="resume-arrow-svg" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.44403 0.47296L5.87709 2.90602L0.451374 8.33173L1.65574 9.57259L7.0997 4.12863L9.52667 6.5556V0.47296L3.44403 0.47296Z" fill="#E2E8F0"/>
                </svg>
            </a>
        </>
    );
}

export default ResumeDownloadBtn;
