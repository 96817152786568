import "./AboutMeTab.css"

function AboutMeTab(props){
    return (
        <>
            <div className="about-me">
                <h1 id = "about" className="disappearing-header">
                    ABOUT
                </h1>
                <h2 className="quote">
                    "{props.personalQuote || "NaN"}"
                </h2>
                <h3 className="caption-1">
                Am a young, curious adult from a small island, Penang, in Malaysia, exploring the world of tech. I enjoy building software in the sweet spot where design and engineering meet — things that look good but are also built well under the hood in my free time.
                </h3>
                <h4 className="caption-2">
                I do, as Gen-Z’s call it, <span style={{ color: '#E2E8F0' }}>touch grass</span> tho. When I’m away from the computer, I’m usually at the gym, exploring nature and nearby cafés, or hanging out with my dogs.
                </h4>
            </div>
        </>
    );
}

export default AboutMeTab