import './ProjectsTab.css';
import Project from '../components/Project';

// Helper function to convert date string to Date object
const parseDate = (dateString) => {
    const [month, year] = dateString.split('/');
    return new Date(year, month - 1); // Note: months are 0-indexed
};

function ProjectsTab(props) {
    // Sort projects by date in descending order
    const sortedProjects = props.projectList
        .map(project => ({ ...project, parsedDate: parseDate(project.date) }))
        .sort((a, b) => b.parsedDate - a.parsedDate)
        .map(({ parsedDate, ...project }) => project);

    return (
        <>
            <h1 id="projects" className="disappearing-header">
                PROJECTS
            </h1>
            <div className='proj-container'>
                {sortedProjects.map((proj) => (
                    <Project
                        key={proj.id || 'NaN'}
                        title={proj.name || 'NaN'}
                        hyperlink={proj.hyperlink || 'NaN'}
                        imgSrcSmall={proj.imgSrcSmall || 'NaN'}
                        imgSrcLarge={proj.imgSrcLarge || 'NaN'}
                        imgAltSmall={proj.imgAltSmall || 'NaN'}
                        imgAltLarge={proj.imgAltLarge || 'NaN'}
                        details={proj.description || 'NaN'}
                        languages={proj.languages || 'NaN'}
                    />
                ))}
            </div>
        </>
    );
}

export default ProjectsTab;
