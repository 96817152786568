import './LanguageFrame.css';

function LanguageFrame({ text }) {
  return (
    <>
        <div className='lang-frame'>
            { text }
        </div>
    </>
  );
}

export default LanguageFrame;
