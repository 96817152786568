import React, { useState, useEffect } from "react";
import "./NavBtnTab.css"
import NavButton from "../components/NavButton";

function NavBtnTab() {
    const [activeButton, setActiveButton] = useState(null); // Manage the active button state
    const [headerPositions, setHeaderPositions] = useState([]); // Store positions of headers
    const [prevScrollY, setPrevScrollY] = useState(0); // Track previous scroll position
    const scrollThresh = 50;

    // Function to update header positions
    const updateHeaderPositions = () => {
        const headers = document.querySelectorAll(".disappearing-header");
        const positions = Array.from(headers).map(header => ({
            id: header.id,
            top: header.getBoundingClientRect().top + window.scrollY // Top position relative to the document
        }));
        setHeaderPositions(positions);
    };

    useEffect(() => {
        // Initial position update
        updateHeaderPositions();

        const handleResize = () => {
            updateHeaderPositions();
        };

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        return () => {
            // Clean up event listener on component unmount
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array to run only on mount/unmount


    useEffect(() => {
        const handleNavigation = () => {
            const currentScrollY = window.scrollY;
    
            let currentHeaderId = null;
            headerPositions.forEach(header => {
                if (Math.abs(currentScrollY - header.top) <= scrollThresh) {
                    currentHeaderId = header.id;
                }
            });
    
            if (currentHeaderId) {
                if (currentScrollY > prevScrollY) {
                    setActiveButton(currentHeaderId);
                } else {
                    const currentIndex = headerPositions.findIndex(header => header.id === currentHeaderId);
                    if (currentIndex > 0) {
                        setActiveButton(headerPositions[currentIndex - 1].id);
                    } else {
                        setActiveButton(currentHeaderId);
                    }
                }
            }
    
            setPrevScrollY(currentScrollY);
        };
    
        window.addEventListener("scroll", handleNavigation);
    
        return () => window.removeEventListener("scroll", handleNavigation);
    }, [prevScrollY, headerPositions]); // Add headerPositions here
    
    return (
        <>
            <div className="nav-btn-container">
                <NavButton 
                    title="about" 
                    id="about" 
                    isActive={activeButton === "about"} 
                    onClick={() => setActiveButton("about")} 
                />
                <NavButton 
                    title="experience" 
                    id="experience" 
                    isActive={activeButton === "experience"} 
                    onClick={() => setActiveButton("experience")} 
                />
                <NavButton 
                    title="projects" 
                    id="projects" 
                    isActive={activeButton === "projects"} 
                    onClick={() => setActiveButton("projects")} 
                />
            </div>
        </>
    );
}

export default NavBtnTab;