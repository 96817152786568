import './FooterTab.css'

function FooterTab() {
  return (
    <>
        <p className='footer'>
            Loosely designed in 
            <a href="https://www.figma.com/" target="_blank" rel="noopener noreferrer" className="footer-link">Figma</a>
            and coded in 
            <a href="https://code.visualstudio.com/" target="_blank" rel="noopener noreferrer" className="footer-link">Visual Studio Code</a>
            . Built with 
            <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer" className="footer-link">React</a>
            . All text is set in 
            <a href="https://rsms.me/inter/" target="_blank" rel="noopener noreferrer" className="footer-link">Inter</a>
            typeface.
        </p>
    </>
  );
}

export default FooterTab;
