import "./HeaderTab.css"
import NavBtnTab from "./NavBtnTab";

function HeaderTab(props) {

    const displayName = props.firstName + " " + props.lastName;

    return (
        <>
            <div className="left-container">
                <div className = "header-frame">
                    <div className = "name-position-frame">
                        <h1 className="header-name">
                            {displayName}
                        </h1>
                        <h2 className="header-current-role">
                            {props.currentJob || "NaN"}
                        </h2>
                    </div>
                    <span className="header-quote">
                        {props.headerQuote || "NaN"}
                    </span>
                </div>

                <NavBtnTab />
                        
                <div className = "svg-container">

                    {/* GitHub */}
                    <a href={props.GitHubLink || "NaN"}>
                        <svg className="hoverable-svg" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <title>GitHub</title>
                            <path d="M12.2979 0C10.6829 0 9.08376 0.318095 7.59171 0.936124C6.09966 1.55415 4.74395 2.46001 3.60198 3.60198C1.29567 5.90829 0 9.03631 0 12.2979C0 17.7336 3.5295 22.3453 8.41178 23.981C9.02668 24.0793 9.22344 23.6981 9.22344 23.3661V21.2877C5.81692 22.0256 5.09134 19.6398 5.09134 19.6398C4.52564 18.2132 3.72627 17.832 3.72627 17.832C2.60716 17.0695 3.81236 17.0941 3.81236 17.0941C5.04215 17.1802 5.69394 18.3608 5.69394 18.3608C6.76386 20.2301 8.57165 19.6767 9.27263 19.3815C9.38332 18.5822 9.70306 18.0411 10.0474 17.7336C7.31726 17.4262 4.45185 16.3685 4.45185 11.683C4.45185 10.318 4.91917 9.22344 5.71853 8.35029C5.59556 8.04284 5.16513 6.76386 5.84151 5.10364C5.84151 5.10364 6.87454 4.77159 9.22344 6.35803C10.195 6.08747 11.2526 5.9522 12.2979 5.9522C13.3432 5.9522 14.4009 6.08747 15.3724 6.35803C17.7213 4.77159 18.7543 5.10364 18.7543 5.10364C19.4307 6.76386 19.0003 8.04284 18.8773 8.35029C19.6767 9.22344 20.144 10.318 20.144 11.683C20.144 16.3808 17.2663 17.4139 14.5238 17.7213C14.9666 18.1025 15.3724 18.8527 15.3724 19.9964V23.3661C15.3724 23.6981 15.5692 24.0916 16.1964 23.981C21.0786 22.333 24.5958 17.7336 24.5958 12.2979C24.5958 10.6829 24.2778 9.08376 23.6597 7.59171C23.0417 6.09966 22.1358 4.74395 20.9939 3.60198C19.8519 2.46001 18.4962 1.55415 17.0041 0.936124C15.5121 0.318095 13.9129 0 12.2979 0Z" fill="#94A3B8"/>
                        </svg>
                    </a>

                    {/* LinkedIn */}
                    <a href={props.LinkedinLink || "NaN"}>
                        <svg className = "hoverable-svg" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <title>LinkedIn</title>
                            <path d="M21.9292 0C22.6364 0 23.3147 0.280951 23.8148 0.781048C24.3149 1.28115 24.5958 1.95942 24.5958 2.66667V21.3333C24.5958 22.0406 24.3149 22.7189 23.8148 23.219C23.3147 23.719 22.6364 24 21.9292 24H3.26249C2.55525 24 1.87697 23.719 1.37687 23.219C0.876777 22.7189 0.595825 22.0406 0.595825 21.3333V2.66667C0.595825 1.95942 0.876777 1.28115 1.37687 0.781048C1.87697 0.280951 2.55525 0 3.26249 0H21.9292ZM21.2625 20.6667V13.6C21.2625 12.4472 20.8045 11.3416 19.9894 10.5264C19.1742 9.71128 18.0686 9.25333 16.9158 9.25333C15.7825 9.25333 14.4625 9.94667 13.8225 10.9867V9.50667H10.1025V20.6667H13.8225V14.0933C13.8225 13.0667 14.6492 12.2267 15.6758 12.2267C16.1709 12.2267 16.6457 12.4233 16.9958 12.7734C17.3458 13.1235 17.5425 13.5983 17.5425 14.0933V20.6667H21.2625ZM5.76916 7.41333C6.36324 7.41333 6.933 7.17733 7.35308 6.75725C7.77316 6.33717 8.00916 5.76742 8.00916 5.17333C8.00916 3.93333 7.00916 2.92 5.76916 2.92C5.17154 2.92 4.59839 3.1574 4.17581 3.57999C3.75323 4.00257 3.51583 4.57571 3.51583 5.17333C3.51583 6.41333 4.52916 7.41333 5.76916 7.41333ZM7.62249 20.6667V9.50667H3.92916V20.6667H7.62249Z" fill="#94A3B8"/>
                        </svg>
                    </a>

                    {/* Instagram */}
                    <a href={props.InstagramLink || "NaN"}>
                        <svg className = "hoverable-svg" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <title>Instagram</title>
                            <path d="M7.55583 0H17.6358C21.4758 0 24.5958 3.12 24.5958 6.96V17.04C24.5958 18.8859 23.8625 20.6562 22.5573 21.9615C21.252 23.2667 19.4817 24 17.6358 24H7.55583C3.71583 24 0.595825 20.88 0.595825 17.04V6.96C0.595825 5.11409 1.32911 3.34379 2.63436 2.03854C3.93962 0.733284 5.70992 0 7.55583 0ZM7.31583 2.4C6.17009 2.4 5.07128 2.85514 4.26112 3.6653C3.45097 4.47546 2.99583 5.57426 2.99583 6.72V17.28C2.99583 19.668 4.92783 21.6 7.31583 21.6H17.8758C19.0216 21.6 20.1204 21.1449 20.9305 20.3347C21.7407 19.5245 22.1958 18.4257 22.1958 17.28V6.72C22.1958 4.332 20.2638 2.4 17.8758 2.4H7.31583ZM18.8958 4.2C19.2937 4.2 19.6752 4.35804 19.9565 4.63934C20.2378 4.92064 20.3958 5.30218 20.3958 5.7C20.3958 6.09782 20.2378 6.47936 19.9565 6.76066C19.6752 7.04197 19.2937 7.2 18.8958 7.2C18.498 7.2 18.1165 7.04197 17.8352 6.76066C17.5539 6.47936 17.3958 6.09782 17.3958 5.7C17.3958 5.30218 17.5539 4.92064 17.8352 4.63934C18.1165 4.35804 18.498 4.2 18.8958 4.2ZM12.5958 6C14.1871 6 15.7132 6.63214 16.8385 7.75736C17.9637 8.88258 18.5958 10.4087 18.5958 12C18.5958 13.5913 17.9637 15.1174 16.8385 16.2426C15.7132 17.3679 14.1871 18 12.5958 18C11.0045 18 9.4784 17.3679 8.35318 16.2426C7.22797 15.1174 6.59583 13.5913 6.59583 12C6.59583 10.4087 7.22797 8.88258 8.35318 7.75736C9.4784 6.63214 11.0045 6 12.5958 6ZM12.5958 8.4C11.641 8.4 10.7254 8.77928 10.0502 9.45442C9.37511 10.1295 8.99583 11.0452 8.99583 12C8.99583 12.9548 9.37511 13.8705 10.0502 14.5456C10.7254 15.2207 11.641 15.6 12.5958 15.6C13.5506 15.6 14.4663 15.2207 15.1414 14.5456C15.8165 13.8705 16.1958 12.9548 16.1958 12C16.1958 11.0452 15.8165 10.1295 15.1414 9.45442C14.4663 8.77928 13.5506 8.4 12.5958 8.4Z" fill="#94A3B8"/>
                        </svg>
                    </a>

                    {/* Mail */}
                    <a href={`mailto:${props.MailLink || "NaN"}`}>
                        <svg className = "hoverable-svg" width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <title>Mail</title>
                            <path d="M3.59582 24C2.77083 24 2.06483 23.7065 1.47783 23.1195C0.890825 22.5325 0.596825 21.826 0.595825 21V3C0.595825 2.175 0.889825 1.469 1.47783 0.882C2.06583 0.295 2.77183 0.001 3.59582 0H27.5958C28.4208 0 29.1273 0.294 29.7153 0.882C30.3033 1.47 30.5968 2.176 30.5958 3V21C30.5958 21.825 30.3023 22.5315 29.7153 23.1195C29.1283 23.7075 28.4218 24.001 27.5958 24H3.59582ZM15.5958 13.5L27.5958 6V3L15.5958 10.5L3.59582 3V6L15.5958 13.5Z" fill="#94A3B8"/>
                        </svg>
                    </a>
                </div>
            </div>
            
        </>
    );
}

export default HeaderTab;