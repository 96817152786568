import "./Project.css";
import LanguageFrame from "./LanguageFrame";

function Project(props) {
    // Sort languages in ascending alphabetical order
    const sortedLanguages = [...props.languages].sort();
    
    return (
        <>
            <a href={props.hyperlink} className="proj-outer-container" target="_blank" rel="noopener noreferrer">
                <div className="proj-left-container">
                    <img className="proj-img-small" src = {props.imgSrcSmall} width = "90%" alt = {props.imgAltSmall}/>
                    <img className="proj-img-large" src = {props.imgSrcLarge} width = "100%" alt = {props.imgAltLarge}/>
                </div>

                <div className="proj-right-container">
                    <div className="proj-header-container">
                        <h1 className="proj-header">{props.title}</h1>
                        <svg className="proj-arrow-svg" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.44403 0.47296L5.87709 2.90602L0.451374 8.33173L1.65574 9.57259L7.0997 4.12863L9.52667 6.5556V0.47296L3.44403 0.47296Z" fill="#E2E8F0"/>
                        </svg>
                    </div>


                    <p className="proj-details">
                        {props.details}
                    </p>

                    <div className="lang-container">
                        {sortedLanguages.map((language, index) => (
                            <LanguageFrame key={index} text={language} />
                        ))}
                    </div>
                </div>
            </a>
        </>
    );
}

export default Project;
