import './ExperienceTab.css'
import Experience from '../components/Experience'
import ResumeDownloadBtn from '../components/ResumeDownloadBtn';

function ExperienceTab(props) {

    const experienceList = props.experienceList;
    
    if (!experienceList || experienceList.length === 0) {
        return (
            <>
                <h1 id = "experience" className="disappearing-header">
                    EXPERIENCE
                </h1>
            </>
        );
    }

    return (
        <>
            <h1 id = "experience" className="disappearing-header">
                EXPERIENCE
            </h1>

            <div className='experience-container'>
                {experienceList.map((exp, index) => (
                    <Experience
                        key={exp.id || 'NaN'}
                        startDate={exp.start_date || 'NaN'}
                        endDate={exp.end_date || 'NaN'}
                        role={exp.role || 'NaN'}
                        team={exp.team || 'NaN'}
                        company={exp.company || 'NaN'}
                        link={exp.link || 'NaN'}
                        details={exp.details || []}
                        languages={exp.languages || []}
                    />
                ))}
            </div>

            <ResumeDownloadBtn hyperlink = {props.resumeLink}/>
        </>
    );
}

export default ExperienceTab;
