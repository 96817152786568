async function LoadJson(url, method = 'GET', body = null) {
    try {
        const options = {
            method,
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(url, options);

        if (!response.ok) {
            return { DATA: null, ERROR: 'Disconnected due to network response.' };
        }

        const data = await response.json();
        return { DATA: data, ERROR: null };

    } catch (error) {
        return { DATA: null, ERROR: error.message || 'An error occurred' };
    }
}

export default LoadJson;
