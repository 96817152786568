import { useEffect } from 'react';

const RadialGradientBgEffect = () => {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX: x, clientY: y } = e;
      const scrollX = window.pageXOffset; // Current horizontal scroll position
      const scrollY = window.pageYOffset; // Current vertical scroll position

      // Update the background style
      document.body.style.background = `radial-gradient(600px at ${x + scrollX}px ${y + scrollY}px, rgba(29, 78, 216, 0.15), transparent 80%)`;
    };

    // Add event listener
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
};

export default RadialGradientBgEffect;
