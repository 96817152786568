import "./NavButton.css";
import PropTypes from "prop-types";
import React, { useState } from "react";

function NavButton(props) {
    const { isActive, onClick } = props;
    const [hovered, setHovered] = useState(false);

    const handleClick = () => {
        onClick(); // Trigger the onClick passed from the parent to set active button
        const element = document.getElementById(props.id);
        if (element) {
            const rect = element.getBoundingClientRect();
            const offsetTop = rect.top + window.pageYOffset - 96;
            
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div 
            className={`container ${isActive ? 'active' : ''}`} 
            onMouseEnter={() => setHovered(true)} 
            onMouseLeave={() => setHovered(false)}
            onClick={handleClick}
        >
            <svg 
                className="line-svg" 
                width={hovered || isActive ? "64" : "32"} 
                height="2" 
                viewBox="0 0 64 2" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">

                <path opacity="0.8" d="M0 0.25L64 0.25V1.75L0 1.75L0 0.25Z" fill="#E2E8F0"/>
            </svg>
            <div className="nav-btn-title">{props.title.toUpperCase()}</div>
        </div>
    );
}

NavButton.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string,
    isActive: PropTypes.bool.isRequired, // New prop for active state
    onClick: PropTypes.func.isRequired // New prop for click handler
};

export default NavButton;
